<template>
  <div class="">
    <v-card>
      <v-card-title class="">
        <h4>Select {{ type }}</h4>
      </v-card-title>
      <v-divider class=""></v-divider>
      <v-card-text>
        <v-container v-if="!isLoading" class="">
          <v-row class="l-dialog__body">
            <v-col cols="12" sm="6" class="l-dialog-right">
              <div class="search-box d-flex align-center">
                <v-text-field v-model="params.search" hide-details="" placeholder="Enter keyword to search">
                  <template v-slot:append>
                    <i class="mt-2 mr-2 fz-14 fas fa-search"> </i>
                  </template>
                </v-text-field>
                <i
                  v-if="isIconAddAll"
                  @click="checkAll"
                  class="px-2 mt-1 mdi mdi-plus-circle-multiple-outline mdi-24px pointer"
                ></i>
              </div>

              <v-divider class=""></v-divider>
              <div id="selector" ref="selector" class="selector mt-2 pt-1 px-1" @scroll="handleScroll">
                <template v-if="document.length > 0">
                  <div v-for="(i, k) in document" :key="i._id" class="mb-2">
                    <div class="d-flex align-center">
                      <v-avatar tile size="55" style="outline: #dddd solid 1px; ">
                        <img
                          v-if="type === 'products'"
                          :src="
                            i.images && i.images.length > 0
                              ? imageHelpers.url(i.images[0].src)
                              : '@/assets/images/no-image.png'
                          "
                          alt=""
                        />
                        <img
                          v-else
                          :src="i.image ? imageHelpers.url(i.image.src) : '@/assets/images/no-image.png'"
                          alt=""
                        />
                      </v-avatar>
                      <span class="ml-3 link-add">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <h4 v-bind="attrs" v-on="on">{{ utilities.stringLimit(i.title, 30) }}</h4>
                          </template>
                          <span>{{ i.title }}</span>
                        </v-tooltip>
                      </span>
                      <v-spacer />
                      <i class="fz-20 mdi mdi-plus-circle-outline mdi-24px pointer" @click="addSelection(i, k)"></i>
                    </div>
                  </div>
                </template>
                <div class="d-flex justify-center">
                  <v-btn v-if="isShowLoadmore" @click="Loadmore" small outlined color="primary">
                    Loadmore
                  </v-btn>
                </div>
                <!-- <Empty v-else /> -->
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="l-dialog-left">
              <div class="select-product-box d-flex align-center">
                <h3>Selected {{ type }} ({{ selected.length }})</h3>
                <!-- <img :src="removeAll" alt="" srcset="" /> -->
                <v-spacer />
                <i v-if="isIconRemoveAll" @click="removeAll" class="fas fa-eraser fz-20 pointer"></i>
              </div>
              <v-divider class=""></v-divider>
              <div class="selected mt-2 pt-1 px-1 ">
                <template v-if="selected.length > 0">
                  <div v-for="(i, k) in selected" :key="i._id" class="mb-2">
                    <div class="d-flex align-center">
                      <v-avatar tile size="40" style="outline: #dddd solid 1px; ">
                        <img
                          v-if="type === 'products'"
                          :src="
                            i.images && i.images.length > 0
                              ? imageHelpers.url(i.images[0].src)
                              : '@/assets/images/no-image.png'
                          "
                          alt=""
                        />
                        <img
                          v-else
                          :src="i.image ? imageHelpers.url(i.image.src) : '@/assets/images/no-image.png'"
                          alt=""
                        />
                      </v-avatar>
                      <span class="ml-3 link-add">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <h4 v-bind="attrs" v-on="on">{{ utilities.stringLimit(i.title, 30) }}</h4>
                          </template>
                          <span>{{ i.title }}</span>
                        </v-tooltip>
                      </span>
                      <v-spacer />
                      <i class="mdi mdi-minus-circle-outline mdi-24px pointer" @click="removeSelection(i, k)"></i>
                    </div>
                  </div>
                </template>
                <Empty v-else />
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else><Loading /></v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submitSelection"> Continue with selected {{ this.type }} </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { commonApi } from '@/apis/common';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import utilities from '@/helpers/utilities';
import Loading from '@/components/Loading';
import $ from 'jquery';
export default {
  components: {
    Loading,
  },
  props: {
    isSuperShow: {
      type: Boolean,
      default: false,
    },
    isIconAddAll: {
      type: Boolean,
      default: true,
    },
    isIconRemoveAll: {
      type: Boolean,
      default: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    model: {
      type: String,
      default: 'target',
    },
    type: {
      type: String,
      default: 'products',
    },
    dataSelected: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      document: [],
      imageHelpers,
      imageFake,
      selected: [],
      utilities,
      init: true,
      params: {
        limit: 15,
        page: 1,
        search: '',
        published: true,
      },
      limit: 5,
      executor: null,
    };
  },

  watch: {
    'params.search': function() {
      this.document = [];
      if (this.executor) {
        clearTimeout(this.executor);
        this.executor = null;
      }

      this.executor = setTimeout(() => {
        this.params.page = 1;
        this.getData();
      }, 400);
    },
    document: {
      handler: function() {
        if (this.document.length === 0 && this.params.search === '' && this.init) {
          this.params.page = this.params.page + 1;
          this.getData();
        }
      },
      deep: true,
    },
    dialog() {
      this.document = [];
      this.init = false;
      console.log('this.dialog', this.dialog);
      this.getData();
      if (this.dialog) {
        if (this.$route.params.id || this.isSuperShow) {
          this.selected = JSON.parse(JSON.stringify(this.dataSelected));
        } else {
          this.selected = [];
        }
        // window.removeEventListener('scroll', this.handleScroll);
      } else {
        this.params.search = ''; // Clear search texf if close popup
      }
    },
  },
  methods: {
    Loadmore() {
      this.params.page = this.params.page + 1;
      this.getData();
    },
    handleScroll(e) {
      var messageDisplay = this.$refs.selector;
      // console.log($('#selector').innerHeight());
      // console.log($('#selector').scrollTop(), $('#selector').offsetHeight);
      // messageDisplay.scrollTop = messageDisplay.scrollHeight;
      // let checkScrollTop = e.target.scrollTop;
      // bottom
      // if (messageDisplay.scrollHeight - checkScrollTop <= 600) {
      //   this.params.page = this.params.page + 1;
      //   if (this.executor) {
      //     clearTimeout(this.executor);
      //     this.executor = null;
      //   }
      //   this.executor = setTimeout(() => {
      //     this.getData();
      //   }, 300);
      // }
    },
    submitSelection() {
      this.$emit('returnData', this.selected, this.model);
      this.$emit('update:dialog', false);
      this.selected = [];
      this.document = [];
    },
    checkAll() {
      this.selected = this.selected.concat(this.document);
      this.document = [];
    },
    removeAll() {
      this.document = this.document.concat(this.selected);
      this.selected = [];
    },
    addSelection(i, k) {
      this.document.splice(k, 1);
      this.selected.push(i);
    },
    removeSelection(i, k) {
      this.selected.splice(k, 1);
      this.document.push(i);
    },
    checkListSelected(listSelect) {
      if (this.selected.length > 0 && this.document.length > 0) {
        try {
          this.document = this.document.filter(item => {
            return !this.selected.find(selected => item._id === selected._id);
          });
        } catch (error) {
          //empty
        }
      }
    },
    async getData() {
      try {
        if (this.items.length > 0) {
          this.document = this.items;
        } else {
          let res = await commonApi.get(this.type, this.params);
          if (this.params.search && this.params.search.trim() !== '') {
            this.document = res.data[this.type];
          } else {
            this.document = this.document.concat(res.data[this.type]);
          }
        }
        this.checkListSelected();
      } catch (error) {
        //empty
      }
    },
  },
  async mounted() {
    console.log(`selected item: ${this.type}`);
    if (this.$route.params.id || this.isSuperShow) {
      this.selected = JSON.parse(JSON.stringify(this.dataSelected));
    } else {
      this.selected = [];
    }
    this.init = false;
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
    // this.true;

    window.addEventListener('scroll', () => {});
  },
  created() {},
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    isShowLoadmore() {
      return this.items.length === 0;
    },
  },
};
</script>
<style lang="scss">
.l-dialog {
  .l-dialog__body {
    min-height: 600px;
    border: 1px solid #dddd !important;
    border-radius: 10px;

    .l-dialog-right {
      .search-box {
        height: 50px;
      }
      .selector {
        max-height: 600px;
        overflow-y: auto;
      }
    }
    .l-dialog-left {
      border-left: 1px solid #dddd;
      .select-product-box {
        height: 50px;
      }
      .selected {
        max-height: 600px;
        overflow-y: auto;
      }
    }
  }
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar {
    width: 1px;
    height: 30px;
    visibility: hidden;
  }

  /* Track */
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar-track {
    background: #dddddddd;
  }

  /* Handle */
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar-thumb {
    background: #dddddddd;
  }

  /* Handle on hover */
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar-thumb:hover {
    background: #dddddddd;
  }
}
</style>
