import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/offers/';

function get(params) {
  return storeRequest.get(path, {
    params: { ...params },
  });
}
function count(params) {
  return storeRequest.get(`${path}count`, {
    params: { ...params },
  });
}

function create(data) {
  return storeRequest.post(path, data);
}
function getById(id) {
  return storeRequest.get(path + id);
}

function update(id, data) {
  return storeRequest.put(path + id, {
    offer: data,
  });
}

function deleteById(id) {
  return storeRequest.delete(path + id);
}
function remove(id) {
  return storeRequest.delete(path + id);
}

function changeActive(action, ids) {
  return storeRequest.patch('/offers', {
    action: action,
    offerIds: ids,
  });
}
//Lấy 1 widget
//fulfillment of order

export const offerApi = {
  get,
  create,
  update,
  deleteById,
  count,
  getById,
  remove,
  changeActive,
};
