import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

function get(type, params) {
  return storeRequest.get(`/${type}`, {
    params: { ...params },
  });
}
function getOutside(path) {
  return storeRequest.get(path);
}
function count(type, params) {
  return storeRequest.get(`/${type}/count`, {
    params: { ...params },
  });
}

export const commonApi = {
  get,
  getOutside,
  count,
};
