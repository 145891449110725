/* eslint-disable no-async-promise-executor */
import { commonApi } from '@/apis/common';

export const convertIdsToArrays = async (type, array) => {
  return new Promise(async resolve => {
    let data = array.filter(item => item);
    if (data && data.length > 0) {
      try {
        let res = await commonApi.get(`${type}s`, { ids: data.join(','), published: true });
        resolve(res.data[`${type}s`]);
      } catch (error) {
        //empty
      }
    }
    resolve([]);
  });
};
