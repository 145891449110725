var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-card',[_c('v-card-title',{},[_c('h4',[_vm._v("Select "+_vm._s(_vm.type))])]),_c('v-divider',{}),_c('v-card-text',[(!_vm.isLoading)?_c('v-container',{},[_c('v-row',{staticClass:"l-dialog__body"},[_c('v-col',{staticClass:"l-dialog-right",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"search-box d-flex align-center"},[_c('v-text-field',{attrs:{"hide-details":"","placeholder":"Enter keyword to search"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('i',{staticClass:"mt-2 mr-2 fz-14 fas fa-search"})]},proxy:true}],null,false,931590187),model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}}),(_vm.isIconAddAll)?_c('i',{staticClass:"px-2 mt-1 mdi mdi-plus-circle-multiple-outline mdi-24px pointer",on:{"click":_vm.checkAll}}):_vm._e()],1),_c('v-divider',{}),_c('div',{ref:"selector",staticClass:"selector mt-2 pt-1 px-1",attrs:{"id":"selector"},on:{"scroll":_vm.handleScroll}},[(_vm.document.length > 0)?_vm._l((_vm.document),function(i,k){return _c('div',{key:i._id,staticClass:"mb-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticStyle:{"outline":"#dddd solid 1px"},attrs:{"tile":"","size":"55"}},[(_vm.type === 'products')?_c('img',{attrs:{"src":i.images && i.images.length > 0
                            ? _vm.imageHelpers.url(i.images[0].src)
                            : '@/assets/images/no-image.png',"alt":""}}):_c('img',{attrs:{"src":i.image ? _vm.imageHelpers.url(i.image.src) : '@/assets/images/no-image.png',"alt":""}})]),_c('span',{staticClass:"ml-3 link-add"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({},'h4',attrs,false),on),[_vm._v(_vm._s(_vm.utilities.stringLimit(i.title, 30)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(i.title))])])],1),_c('v-spacer'),_c('i',{staticClass:"fz-20 mdi mdi-plus-circle-outline mdi-24px pointer",on:{"click":function($event){return _vm.addSelection(i, k)}}})],1)])}):_vm._e(),_c('div',{staticClass:"d-flex justify-center"},[(_vm.isShowLoadmore)?_c('v-btn',{attrs:{"small":"","outlined":"","color":"primary"},on:{"click":_vm.Loadmore}},[_vm._v(" Loadmore ")]):_vm._e()],1)],2)],1),_c('v-col',{staticClass:"l-dialog-left",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"select-product-box d-flex align-center"},[_c('h3',[_vm._v("Selected "+_vm._s(_vm.type)+" ("+_vm._s(_vm.selected.length)+")")]),_c('v-spacer'),(_vm.isIconRemoveAll)?_c('i',{staticClass:"fas fa-eraser fz-20 pointer",on:{"click":_vm.removeAll}}):_vm._e()],1),_c('v-divider',{}),_c('div',{staticClass:"selected mt-2 pt-1 px-1 "},[(_vm.selected.length > 0)?_vm._l((_vm.selected),function(i,k){return _c('div',{key:i._id,staticClass:"mb-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticStyle:{"outline":"#dddd solid 1px"},attrs:{"tile":"","size":"40"}},[(_vm.type === 'products')?_c('img',{attrs:{"src":i.images && i.images.length > 0
                            ? _vm.imageHelpers.url(i.images[0].src)
                            : '@/assets/images/no-image.png',"alt":""}}):_c('img',{attrs:{"src":i.image ? _vm.imageHelpers.url(i.image.src) : '@/assets/images/no-image.png',"alt":""}})]),_c('span',{staticClass:"ml-3 link-add"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({},'h4',attrs,false),on),[_vm._v(_vm._s(_vm.utilities.stringLimit(i.title, 30)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(i.title))])])],1),_c('v-spacer'),_c('i',{staticClass:"mdi mdi-minus-circle-outline mdi-24px pointer",on:{"click":function($event){return _vm.removeSelection(i, k)}}})],1)])}):_c('Empty')],2)],1)],1)],1):_c('v-container',[_c('Loading')],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitSelection}},[_vm._v(" Continue with selected "+_vm._s(this.type)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }