<template>
  <div class="handpick-page--create">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Handpicked products" route="website.apps.widgets.handpicked" />
        <div class="page-header">
          <h2 class="page-title">
            {{ mode === 'create' ? 'Create handpicked products' : 'Update handpicked products' }}
          </h2>
        </div>
      </v-col>
    </v-row>
    <v-col cols="12">
      <Notification type="error" :messages="errors" v-if="errors.length > 0" />
    </v-col>
    <div></div>
    <v-row no-gutters class="handpick-box">
      <v-col cols="12" class="pa-3">
        <div><h3>When customers view these products/collections:</h3></div>
        <v-radio-group hide-details="" v-model="typeView" row>
          <v-radio label="All Products" value="all"></v-radio>
          <v-radio label="Specific products" value="any"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col v-if="typeView === 'any'" cols="12" class="px-3">
        <div class="d-flex">
          <v-btn
            small
            elevation=""
            color="primary"
            @click="configDialogSelection({ type: 'products', model: 'handpickedProductIds' })"
            >Select products</v-btn
          >
          <div class="ml-2">
            <span v-if="formData.handpickedProductIds"
              >{{ formData.handpickedProductIds.length }} product selected</span
            >
            <span v-else>No product selected </span>
          </div>
        </div>
        <div class="my-3 d-flex">
          <v-btn
            small
            elevation=""
            color="primary"
            @click="configDialogSelection({ type: 'collections', model: 'handpickedCollectionIds' })"
            >Select collections</v-btn
          >
          <div class="ml-2">
            <span v-if="formData.handpickedCollectionIds"
              >{{ formData.handpickedCollectionIds.length }} collections selected</span
            >
            <span v-else>No collections selected </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="handpick-box mt-4">
      <v-col cols="12" class="pa-3">
        <div><h3>Recommend these products</h3></div>
        <div class="d-flex">
          <span>{{ formData.recommendIds.length }} product selected </span><v-spacer></v-spacer>
          <v-btn
            small
            elevation=""
            color="primary"
            @click="configDialogSelection({ type: 'products', model: 'recommendIds' })"
            >Select products</v-btn
          >
        </div>
        <div v-if="formData.recommendIds.length === 0" class="d-flex justify-center result-product-recommen">
          <Empty />
        </div>
        <v-col cols="12" class="d-flex justify-center text-error" v-if="formData.recommendIds.length === 0">
          <div v-if="isShowMessageRecomentProduct">Recommend product(s) must greater than 0</div>
        </v-col>
        <div v-else>
          <v-col cols="12"> <i class="fas fa-bars"></i> Drag to rearrange </v-col>
          <v-col>
            <draggable v-model="formData.recommendIds" @change="changeDrag" class="box-recommend--handpicked">
              <transition-group>
                <template v-for="(product, k) in formData.recommendIds" class="d-flex">
                  <span :key="product._id" class="pa-2 ">
                    <div class="pt-1 d-flex align-center item-handpicked">
                      <v-avatar tile size="130" color="#dddd" class="text-center pointer mr-3">
                        <img
                          :src="
                            product && product.images[0]
                              ? imageHelpers.url(product.images[0].src, { width: 300, height: 300 })
                              : '@/assets/images/no-image.png'
                          "
                          alt=""
                          srcset=""
                        />
                      </v-avatar>
                      <i @click="removeProductRecomment(k)" class="far fa-times-circle"></i>
                    </div>
                  </span>
                </template>
              </transition-group>
            </draggable>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <v-col cols="12" class="my-3">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" class="d-flex">
      <v-spacer></v-spacer>
      <!-- <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn> -->
      <!-- ở màn create thì chỉ đc click once để tránh duplice sp, khi qua update thì save thoải mái -->
      <v-btn v-if="mode === 'create'" :loading="isLoading" color="primary" @click.once="onSubmit">Create</v-btn>
      <v-btn v-else :loading="isLoading" color="primary" @click="onSubmit">Save</v-btn>
    </v-col>
    <v-dialog :scrollable="false" v-model="dialog" width="900" content-class="l-dialog">
      <SelectionItems
        :model="configSelectionItem.model"
        :type="configSelectionItem.type"
        @returnData="getDataSelected"
        :dialog.sync="dialog"
        :isSuperShow="true"
        :dataSelected="configSelectionItem.data"
      />
    </v-dialog>
    <BeforeLeavePage />
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import SelectionItems from '../../component/SelectionItems';
import { offerApi } from '@/apis/offers';
import { convertIdsToArrays } from '@/helpers/convertData';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import utilities from '@/helpers/utilities';
import { log } from 'handlebars';
export default {
  components: {
    SelectionItems,
    draggable,
  },
  data() {
    return {
      imageHelpers,
      imageFake,
      utilities,
      typeView: 'all',
      errors: [],
      configSelectionItem: {
        type: 'products',
        model: 'targetIds',
        data: [],
      },
      dialog: false,
      isLoading: false,
      typeTarget: 'all',
      dataDefault: {},
      itemsMessage: [
        {
          name: 'Frequently bought with {{product name}}',
          id: 'Frequently bought with {{product name}}',
        },
        {
          name: 'Complete your order with {{product name}}',
          id: 'Complete your order with {{product name}}',
        },
        {
          name: 'Best deal today for lucky customers like you',
          id: 'Best deal today for lucky customers like you',
        },
      ],
      mode: 'create',
      isShowMessageRecomentProduct: false,
      formData: {
        activated: true,
        offerMessage: 'Wait {{last_name}}! Weve got a special offer for you that complements your purchase.',
        offerType: 'handpicked',
        priority: 0,
        offerName: 'handpicked',
        offerTitle: null,
        recommendIds: [], // product collection ids ,
        recommendType: 'product', //product, collection, sameCollection, relevantCollection
        targetIds: [],
        handpickedCollectionIds: [],
        handpickedProductIds: [],
        targetType: 'handpicked', //all, product, collection
        store: '',
        enableDiscount: false,
        percentageDiscount: 0,
      },
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.mode = 'edit';
      try {
        let res = (await offerApi.getById(this.$route.params.id)).data.offer;
        let [
          collectionRecommendIds,
          collectionTargetIds,
          productRecommendIds,
          productTargetIds,
          handpickedProductIds,
          handpickedCollectionIds,
        ] = await Promise.all([
          convertIdsToArrays('collection', res.collectionRecommendIds),
          convertIdsToArrays('collection', res.collectionTargetIds),
          convertIdsToArrays('product', res.productRecommendIds),
          convertIdsToArrays('product', res.productTargetIds),
          convertIdsToArrays('product', res.handpickedProductIds),
          convertIdsToArrays('collection', res.handpickedCollectionIds),
        ]);
        this.dataDefault = {
          collectionRecommendIds,
          collectionTargetIds,
          productRecommendIds,
          productTargetIds,
          handpickedProductIds,
          handpickedCollectionIds,
          recommendIds: productRecommendIds,
        };

        this.formData = {
          activated: res.activated,
          offerMessage: res.offerMessage,
          offerType: res.offerType,
          priority: res.priority,
          offerName: res.offerName,
          offerTitle: res.offerTitle,
          recommendIds: this.reOderRecommentProduct(res.productRecommendIds, productRecommendIds), // product  ids ,
          recommendType: res.recommendType, //product, collection, sameCollection, relevantCollection
          targetIds: [],
          targetType: res.targetType, //all, product, collection
          enableDiscount: res.enableDiscount,
          percentageDiscount: res.percentageDiscount,
          handpickedProductIds: handpickedProductIds,
          handpickedCollectionIds: handpickedCollectionIds,
          // discountData: res.discountData,
        };
        if (res.handpickedCollectionIds.length === 0 && res.handpickedProductIds.length === 0) {
          this.typeView = 'all';
        } else this.typeView = 'any';
        this.defaultFormData = Object.assign({}, res);
        // if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
        //   console.log(this.formData.recommendType, res[`${this.formData.recommendType}RecommendIds`]);
        //   this.formData.recommendIds = res[`${this.formData.recommendType}RecommendIds`];
        //   this.dataDefault[`${this.formData.recommendType}RecommendIds`] = this.dataDefault[
        //     `${this.formData.recommendType}RecommendIds`
        //   ];
        // }
        if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
          this.formData.targetIds = this.formData.targetIds.concat(
            this.dataDefault[`${this.formData.targetType}TargetIds`],
          );
          this.dataDefault[`${this.formData.targetType}TargetIds`] = this.dataDefault[
            `${this.formData.targetType}TargetIds`
          ];
        }
        this.$forceUpdate();
      } catch (error) {
        //empty
      }
      this.componentMount = true;
    }
  },
  watch: {
    'formData.targetType': function() {
      if (this.formData.targetIds === 'all') {
        this.formData.handpickedCollectionIds = [];
        this.formData.handpickedProductIds = [];
      }
    },
    'formData.recommendType': function() {
      this.formData.recommendIds = [];
    },
  },
  methods: {
    reOderRecommentProduct(order, array) {
      console.log(order, array);
      let newArray = [];
      for (let i = 0; i < order.length; i++) {
        let e = array.find(item => {
          return item._id === order[i];
        });
        if (e) {
          newArray.push(e);
        }
      }
      return newArray;
    },
    removeProductRecomment(k) {
      this.formData.recommendIds.splice(k, 1);
    },
    changeDrag() {},
    onCancel() {},
    async onSubmit() {
      if (this.formData.recommendIds.length > 0) {
        let convertData = Object.assign({}, this.formData);
        convertData.handpickedCollectionIds = convertData.handpickedCollectionIds.map(item => item._id);
        convertData.handpickedProductIds = convertData.handpickedProductIds.map(item => item._id);
        convertData.productRecommendIds = convertData.recommendIds.map(item => item._id);
        convertData.store = this.$store.state.auth.accountInfo.storeId;
        if (this.typeView === 'all') {
          delete convertData.handpickedCollectionIds;
          delete convertData.handpickedProductIds;
        }
        delete convertData.recommendIds;
        try {
          let response = null;
          if (this.mode === 'edit') {
            response = await offerApi.update(this.$route.params.id, convertData);
          } else {
            response = await offerApi.create({
              offer: convertData,
            });
            this.$router.push({
              name: 'website.apps.widgets.handpicked.update',
              params: { id: response.data.offer._id },
            });
          }
        } catch (error) {
          console.log(error);
          //empty
        }
      } else {
        this.isShowMessageRecomentProduct = true;
      }
    },
    configDialogSelection(data) {
      this.dialog = true;
      this.configSelectionItem = Object.assign({}, data);
      this.configSelectionItem.data = this.dataDefault[data.model];
      if (this.formData.handpickedProductIds && data.model === 'handpickedProductIds') {
        this.configSelectionItem.data = this.formData.handpickedProductIds;
      }
      if (this.formData.handpickedCollectionIds && data.model === 'handpickedCollectionIds') {
        this.configSelectionItem.data = this.formData.handpickedCollectionIds;
      }
      if (this.formData.recommendIds && data.model === 'recommendIds') {
        this.configSelectionItem.data = this.formData.recommendIds;
      }
    },
    getDataSelected(data, model) {
      this.formData[model] = JSON.parse(JSON.stringify(data));
      // this.configSelectionItem.data = JSON.parse(JSON.stringify(data));
    },
  },
};
</script>

<style lang="scss" scoped>
.handpick-page--create {
  min-width: 800px;
  .handpick-box {
    border: 1px solid #e6ebf0;
    border-radius: 12px;
  }
  .result-product-recommen {
    min-height: 300px;
    align-items: center;
  }
  .box-recommend--handpicked {
    span {
      display: flex;
      flex-wrap: wrap;
    }
    .item-handpicked {
      position: relative;
      i {
        position: absolute;
        top: 2px;
        font-size: 20px;
        right: 10px;
        color: #ddd;
        cursor: pointer;
      }
    }
  }
}
</style>
